import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './MainContainer.css';
import {
  AudioSample,
  AudioSamplesFromServerState,
  VariationPlan,
  VariationValue
} from '../../types/AudioSampleState';
import { KSCSliderState } from '../../types/KSCSliderState';
import axios from 'axios';
import { playAudio, playAudioWithNewAudioPlayer } from '../../tools/playAudio';
import GenerateButton from '../Buttons/GenerateButton';
import WaveFormContainer from '../WaveForm/WaveFormContainer';
import VariationContainer from '../VariationContainer/VariationContainer';
import RotarySliderContainer from '../RotarySliderContainer/RotarySliderContainer';
import WorkSpaces from '../WorkSpaces/WorkSpaces';
import { WorkSpacesState } from '../../types/WorkSpace';
import { useKeyPress } from '../../hooks/UseKeyPress';
import { VariationState, variationInititalState } from '../../types/VariationState';
import {
  getNewlatentVectorFromAValueWithVectorVariationPlan,
  getNewRandomlatentVector,
  getNewVariationPlanVector
} from '../../tools/vectorManipulations';
import OneDVariationContainer from '../VariationContainer/OneDVariationContainer';
import DragAndDropContainer from '../DragAndDrop/DragAndDropContainer';
import { motion } from 'framer-motion';
import useArrowPressed from '../../hooks/UseArrowPressed';
import CustomSnackBar from '../UI/CustomSnackBar';
import { isMobile } from 'react-device-detect';
import MobileMainContainer from '../MobileMainContainer/MobileMainContainer';

interface MainContainerProps {
  variationState: VariationState;
  setVariationState: React.Dispatch<React.SetStateAction<VariationState>>;
  KSCSliderState: KSCSliderState;
  setKSCSliderState: React.Dispatch<React.SetStateAction<KSCSliderState>>;
  audioSamplesFromServerState: AudioSamplesFromServerState;
  setAudioSamplesFromServerState: React.Dispatch<React.SetStateAction<AudioSamplesFromServerState>>;
  workSpacesState: WorkSpacesState;
  setWorkSpacesState: React.Dispatch<React.SetStateAction<WorkSpacesState>>;
}

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const MainContainer: FC<MainContainerProps> = ({
  variationState,
  setVariationState,
  KSCSliderState,
  setKSCSliderState,
  audioSamplesFromServerState,
  setAudioSamplesFromServerState,
  workSpacesState,
  setWorkSpacesState
}) => {
  /****** State setters *****/
  const audioRef = useRef<any>(null);
  const arrowLeftPressed = useArrowPressed('ArrowLeft');
  const arrowRightPressed = useArrowPressed('ArrowRight');
  const [historyLength] = useState<number>(10);
  const [currentHistoryIndex, setCurrentHistoryIndex] = useState(0);
  /****** End of State setters *****/

  /****** Fetch info from server *****/
  const fetchBlobFromServer = (
    latentVector: number[],
    classes: number[],
    filename: string,
    isOriginal: boolean,
    variationPlan: VariationPlan,
    variationValue: VariationValue,
    shouldPlaySample: boolean
  ) => {
    const data = {
      latent_vector: latentVector,
      classes: classes
    };
    setAudioSamplesFromServerState((data) => {
      return {
        ...data,
        isFetching: true,
        isSuccess: false
      };
    });
    axios
      .post(`${API_ENDPOINT}/sample/`, data, {
        responseType: 'blob',
        withCredentials: true
      })
      .then((result) => {
        const currentSampleFetchedTmp: AudioSample = {
          filename: filename,
          kickValue: classes[1],
          snareValue: classes[2],
          cymbalValue: classes[0],
          variationValue: variationValue,
          blob: result.data,
          latentVector: latentVector,
          currentVariationPlan: variationPlan,
          isOriginal: isOriginal,
          originalSample: isOriginal
            ? null
            : audioSamplesFromServerState.currentSampleFetched.originalSample
            ? audioSamplesFromServerState.currentSampleFetched.originalSample
            : audioSamplesFromServerState.currentSampleFetched
        };
        setAudioSamplesFromServerState((data) => {
          return {
            ...data,
            lastOriginalSample: isOriginal
              ? currentSampleFetchedTmp
              : audioSamplesFromServerState.lastOriginalSample,
            currentSampleFetched: currentSampleFetchedTmp,
            isFetching: false,
            isSuccess: true
          };
        });
        updateInformationAboutWorkSpaceBoxSelected(currentSampleFetchedTmp);
        handleNewSampleInHistory(currentSampleFetchedTmp);
        synchronizeInterfaceDataToCurrentSample(currentSampleFetchedTmp, shouldPlaySample);
      })
      .catch((err) => {
        console.log(err);
        setAudioSamplesFromServerState((data) => {
          return {
            ...data,
            isFetching: false,
            isError: true,
            isSuccess: false,
            errorMessage: 'Error while fetching a sample ...'
          };
        });
      });
  };

  const fetchRandomSample = (shouldPlaySample: boolean) => {
    setAudioSamplesFromServerState((data) => {
      return {
        ...data,
        isFetching: true,
        isLastFetchedSampleAVariation: false,
        isSuccess: false
      };
    });
    const newlatentVector = getNewRandomlatentVector();
    const classes = [
      KSCSliderState.cymbalValue,
      KSCSliderState.kickValue,
      KSCSliderState.snareValue
    ];
    axios
      .post(
        `${API_ENDPOINT}/generate-random-name`,
        {},
        {
          headers: { 'Content-Type': 'application/json' }
        }
      )
      .then((res) => {
        setVariationState(variationInititalState);
        fetchBlobFromServer(
          newlatentVector,
          classes,
          res.data.sample_name,
          true,
          variationInititalState.variationPlan,
          variationInititalState.variationValue,
          shouldPlaySample
        );
      })
      .catch((e) => {
        setAudioSamplesFromServerState((data) => {
          return {
            ...data,
            isFetching: false,
            isError: true,
            isSuccess: false,
            errorMessage: 'Error while fetching a random name'
          };
        });
      });
  };

  useEffect(() => {
    fetchRandomSample(false);
  }, []);
  /****** End of Fetch info from server *****/

  /****** Utility functions *****/
  const getIndexOfWorkSpaceBoxCurrentlySelected = useMemo(() => {
    return workSpacesState.boxes.find((box) => box.isSelected === true)?.index;
  }, [workSpacesState]);
  const currentWorkspaceBoxSelected = useMemo(() => {
    const indexSelected = getIndexOfWorkSpaceBoxCurrentlySelected;
    if (indexSelected) {
      return workSpacesState.boxes[indexSelected];
    }
    return workSpacesState.boxes[0];
  }, [workSpacesState, getIndexOfWorkSpaceBoxCurrentlySelected]);

  const updateInformationAboutWorkSpaceBoxSelected = (newSample: AudioSample) => {
    const indexOfWorkSpaceBoxSelected = getIndexOfWorkSpaceBoxCurrentlySelected;
    const tmp = workSpacesState.boxes.map((box) =>
      box.index === indexOfWorkSpaceBoxSelected ? { ...box, sample: newSample } : box
    );
    setWorkSpacesState({ ...workSpacesState, boxes: tmp });
  };

  const handleNewSampleInHistory = useCallback(
    (newSample: AudioSample) => {
      const historyArray = audioSamplesFromServerState.samplesHistory;
      const newHistoryArray = [...historyArray, newSample];
      const newTmp =
        newHistoryArray.length > historyLength
          ? newHistoryArray.filter((_, index) => index !== 0)
          : newHistoryArray;
      setCurrentHistoryIndex(newTmp.length - 1);
      setAudioSamplesFromServerState((data) => {
        return { ...data, samplesHistory: newTmp };
      });
    },
    [audioSamplesFromServerState]
  );

  const synchronizeInterfaceDataToCurrentSample = useCallback(
    (sample: AudioSample, shouldPlaySample: boolean) => {
      setKSCSliderState({
        kickValue: sample.kickValue,
        snareValue: sample.snareValue,
        cymbalValue: sample.cymbalValue
      });
      setVariationState({
        variationPlan: {
          e1: sample.currentVariationPlan.e1,
          e2: sample.currentVariationPlan.e2
        },
        variationValue: {
          x: sample.variationValue.x,
          y: sample.variationValue.y
        }
      });
      setAudioSamplesFromServerState((data) => {
        return {
          ...data,
          currentSampleFetched: sample
        };
      });
      const url = URL.createObjectURL(sample?.blob);
      shouldPlaySample && playAudio(url, audioRef);
    },
    [workSpacesState.boxes]
  );
  /****** End of Utility functions *****/

  /****** Event handling functions *****/
  useEffect(() => {
    arrowLeftPressed && onArrowPressed('left');
    arrowRightPressed && onArrowPressed('right');
  }, [arrowLeftPressed, arrowRightPressed]);

  const onClickOnGenerateButton = () => {
    !audioSamplesFromServerState.isFetching && fetchRandomSample(true);
    getNewVariationPlanVector();
  };

  const onRadarChartDragEnd = (index: number, value: number) => {
    const newlatentVector = audioSamplesFromServerState.currentSampleFetched.latentVector.slice();
    newlatentVector[index] = value;
    fetchBlobFromServer(
      newlatentVector,
      [KSCSliderState.cymbalValue, KSCSliderState.kickValue, KSCSliderState.snareValue],
      audioSamplesFromServerState.currentSampleFetched.filename,
      false,
      variationState.variationPlan,
      variationState.variationValue,
      true
    );
  };

  const onKSCSliderChange = (
    value: number,
    type: 'Kick' | 'Snare' | 'Cymbal' | "Variation",
    setTheRestToZero: boolean
  ) => {
    switch (type) {
      case 'Kick':
        fetchBlobFromServer(
          audioSamplesFromServerState.currentSampleFetched.latentVector,
          [
            setTheRestToZero ? 0 : KSCSliderState.cymbalValue,
            value,
            setTheRestToZero ? 0 : KSCSliderState.snareValue
          ],
          audioSamplesFromServerState.currentSampleFetched.filename,
          false,
          variationState.variationPlan,
          variationState.variationValue,
          true
        );

        break;
      case 'Snare':
        fetchBlobFromServer(
          audioSamplesFromServerState.currentSampleFetched.latentVector,
          [
            setTheRestToZero ? 0 : KSCSliderState.cymbalValue,
            setTheRestToZero ? 0 : KSCSliderState.kickValue,
            value
          ],
          audioSamplesFromServerState.currentSampleFetched.filename,
          false,
          variationState.variationPlan,
          variationState.variationValue,
          true
        );
        break;
      case 'Cymbal':
        fetchBlobFromServer(
          audioSamplesFromServerState.currentSampleFetched.latentVector,
          [
            value,
            setTheRestToZero ? 0 : KSCSliderState.kickValue,
            setTheRestToZero ? 0 : KSCSliderState.snareValue
          ],
          audioSamplesFromServerState.currentSampleFetched.filename,
          false,
          variationState.variationPlan,
          variationState.variationValue,
          true
        );
        break;
    }
  };

  const onClickOnWorkSpaceBox = (index: number) => {
    // Set the current selected box to the state "selected"
    const workspacesBoxTmp = workSpacesState.boxes.map((box) =>
      box.index === index ? { ...box, isSelected: true } : { ...box, isSelected: false }
    );
    setWorkSpacesState({ ...workSpacesState, boxes: workspacesBoxTmp });
    // Set all the values of sliders to the chosen workspace sample
    const workspaceBoxSelected = workSpacesState.boxes.find((box) => box.index === index);
    if (workspaceBoxSelected?.sample) {
      synchronizeInterfaceDataToCurrentSample(workspaceBoxSelected.sample, true);
    }
  };

  const onClickOnDirectionVariationButton = () => {
    const newVariationPlan = getNewVariationPlanVector();
    setVariationState((data) => {
      return { ...data, variationPlan: newVariationPlan };
    });
    const newlatentVector = getNewlatentVectorFromAValueWithVectorVariationPlan(
      audioSamplesFromServerState.currentSampleFetched.originalSample
        ? audioSamplesFromServerState.currentSampleFetched.originalSample.latentVector
        : audioSamplesFromServerState.currentSampleFetched.latentVector,
      newVariationPlan,
      variationState.variationValue.x
    );
    fetchBlobFromServer(
      newlatentVector,
      [KSCSliderState.cymbalValue, KSCSliderState.kickValue, KSCSliderState.snareValue],
      audioSamplesFromServerState.currentSampleFetched.filename,
      false,
      newVariationPlan,
      variationState.variationValue,
      true
    );
  };

  const onOneDVariationSliderChange = (value: number) => {
    const newlatentVector = getNewlatentVectorFromAValueWithVectorVariationPlan(
      audioSamplesFromServerState.currentSampleFetched.originalSample
        ? audioSamplesFromServerState.currentSampleFetched.originalSample.latentVector
        : audioSamplesFromServerState.currentSampleFetched.latentVector,
      variationState.variationPlan,
      value
    );
    fetchBlobFromServer(
      newlatentVector,
      [KSCSliderState.cymbalValue, KSCSliderState.kickValue, KSCSliderState.snareValue],
      audioSamplesFromServerState.currentSampleFetched.filename,
      false,
      variationState.variationPlan,
      { x: value, y: 0 },
      true
    );
  };

  const onClickOnSampleHistoryButtons = useCallback(
    (mode: string) => {
      if (mode === 'backward') {
        if (audioSamplesFromServerState.currentSampleFetched && currentHistoryIndex >= 1) {
          setCurrentHistoryIndex((state) => state - 1);
          const history: AudioSample[] = audioSamplesFromServerState.samplesHistory;
          const previousSample: AudioSample = history[currentHistoryIndex - 1];
          synchronizeInterfaceDataToCurrentSample(previousSample, true);
          updateInformationAboutWorkSpaceBoxSelected(previousSample);
        }
      } else if (mode === 'forward') {
        if (
          audioSamplesFromServerState.currentSampleFetched &&
          currentHistoryIndex < audioSamplesFromServerState.samplesHistory.length - 1
        ) {
          setCurrentHistoryIndex((state) => state + 1);
          const history: AudioSample[] = audioSamplesFromServerState.samplesHistory;
          const nextSample: AudioSample = history[currentHistoryIndex + 1];
          synchronizeInterfaceDataToCurrentSample(nextSample, true);
          updateInformationAboutWorkSpaceBoxSelected(nextSample);
        }
      }
    },
    [audioSamplesFromServerState.currentSampleFetched]
  );

  const onDroppedAnAudioFile = (file: any) => {
    fetchBlobFromServer(
      file.latent_vector,
      file.classes,
      file.sample_name,
      true,
      variationState.variationPlan,
      variationState.variationValue,
      true
    );
  };

  const onArrowPressed = (value: 'right' | 'left') => {
    const currentIndex = getIndexOfWorkSpaceBoxCurrentlySelected;
    if (value === 'right' && currentIndex !== undefined && currentIndex < 7) {
      onClickOnWorkSpaceBox(currentIndex + 1);
    }
    if (value === 'left' && currentIndex && currentIndex !== 0)
      onClickOnWorkSpaceBox(currentIndex - 1);
  };

  const onKeyPressed = (event: any) => {
    if (event.keyCode === 32 && !audioSamplesFromServerState.isFetching) {
      event.preventDefault();
      const url = URL.createObjectURL(audioSamplesFromServerState.currentSampleFetched.blob);
      playAudio(url, audioRef);
    }
    if (event.keyCode === 13 && !audioSamplesFromServerState.isFetching) {
      fetchRandomSample(true);
    }
    if (event.keyCode === 8 && !audioSamplesFromServerState.isFetching) {
      onClickOnSampleHistoryButtons('backward');
    }
    if (event.key === 'k') {
      onKSCSliderChange(2, 'Kick', true);
    }
    if (event.key === 'l') {
      onKSCSliderChange(2, 'Snare', true);
    }
    if (event.key === 'm') {
      onKSCSliderChange(2, 'Cymbal', true);
    }
  };
  useKeyPress(['k', 'l', 'm'], onKeyPressed, [32, 13, 8, 37, 39]);
  /****** End of Event handling functions *****/

  return (
    <>
      {!isMobile ? (
        <motion.div
          transition={{ duration: 1 }}
          className="main-container-main-container">
          <audio ref={audioRef} />
          <CustomSnackBar
            open={audioSamplesFromServerState.isError}
            onClose={() => null}
            text={audioSamplesFromServerState.errorMessage}
            severity="error"
          />
          <div className="main-container-top-container">
            <div className="main-container-top-left-container">
              <div className="height-33">
                <GenerateButton
                  onClick={onClickOnGenerateButton}
                  onClickOnSampleHistoryButtons={onClickOnSampleHistoryButtons}
                />
              </div>

              <div className="height-33">
                <div className="main-container-top-left-small-container">
                  <DragAndDropContainer onDroppedAnAudioFile={onDroppedAnAudioFile} />
                  <OneDVariationContainer
                    value={variationState.variationValue.x}
                    onOneDVariationSliderChange={onOneDVariationSliderChange}
                    onClickOnDirectionVariationButton={onClickOnDirectionVariationButton}
                    setVariationState={setVariationState}
                  />
                </div>
              </div>
              <div className="height-33">
                <div className="main-container-top-left-small-container">
                  <RotarySliderContainer
                    value={KSCSliderState.kickValue}
                    onChange={onKSCSliderChange}
                    type="Kick"
                    setKSCSliderState={setKSCSliderState}
                  />
                  <RotarySliderContainer
                    value={KSCSliderState.snareValue}
                    onChange={onKSCSliderChange}
                    setKSCSliderState={setKSCSliderState}
                    type="Snare"
                  />
                  <RotarySliderContainer
                    value={KSCSliderState.cymbalValue}
                    onChange={onKSCSliderChange}
                    type="Cymbal"
                    setKSCSliderState={setKSCSliderState}
                  />
                </div>
              </div>
            </div>
            <div className="main-container-top-right-container">
              <div className="height-50">
              <VariationContainer
                audioData={audioSamplesFromServerState}
                onRadarChartDragEnd={onRadarChartDragEnd}
                variationState={variationState}
                workspaceBox={currentWorkspaceBoxSelected}
              />
              </div>
              <div className="height-50">
              <WaveFormContainer
                audioSampleState={audioSamplesFromServerState}
                workspaceBox={currentWorkspaceBoxSelected}
              />
              </div>
            </div>
          </div>
          <div className="main-container-bottom-container">
            <WorkSpaces
              workSpacesState={workSpacesState}
              onClickOnWorkSPaceBox={onClickOnWorkSpaceBox}
              setWorkSpaceState={setWorkSpacesState}
            />
          </div>
        </motion.div>
      ) : (
        <motion.div
          transition={{ duration: 1 }}
          className="main-container-main-container">
          <audio ref={audioRef} />
          <MobileMainContainer
            audioSampleState={audioSamplesFromServerState}
            workspaceBox={currentWorkspaceBoxSelected}
            onRadarChartDragEnd={onRadarChartDragEnd}
            variationState={variationState}
            onClick={onClickOnGenerateButton}
            onClickOnSampleHistoryButtons={onClickOnSampleHistoryButtons}
            workSpacesState={workSpacesState}
            onClickOnWorkSPaceBox={onClickOnWorkSpaceBox}
            setWorkSpaceState={setWorkSpacesState}
            onChange={onKSCSliderChange}
            setKSCSliderState={setKSCSliderState}
            KSCSliderState={KSCSliderState}
            onOneDVariationSliderChange={onOneDVariationSliderChange}
            onClickOnDirectionVariationButton={onClickOnDirectionVariationButton}
            setVariationState={setVariationState}
            value={variationState.variationValue.x}
            onDroppedAnAudioFile={onDroppedAnAudioFile}
          />
        </motion.div>
      )}
    </>
  );
};

export default MainContainer;
