import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import "./MobilePortraitContainer.css";
import { theme } from "../../constants/theme";
import WaveFormContainer from "../WaveForm/WaveFormContainer";
import { AudioSamplesFromServerState } from "../../types/AudioSampleState";
import { WorkSpaceBoxState, WorkSpacesState } from "../../types/WorkSpace";
import VariationContainer from "../VariationContainer/VariationContainer";
import { VariationState } from "../../types/VariationState";
import GenerateButton from "../Buttons/GenerateButton";
import WorkSpaces from "../WorkSpaces/WorkSpaces";
import VerticalSliderContainer from "../VerticalSlider/VerticalSliderContainer";
import { KSCSliderState } from "../../types/KSCSliderState";
import OneDVariationContainer from "../VariationContainer/OneDVariationContainer";
import OneDVariationContainerMobile from "../VariationContainer/OneDVariationContainerMobile";
import SelectAudioContainer from "../SelectAudio/SelectAudioContainer";

interface MobilePortraitContainerProps {
  audioSamplesFromServerState: AudioSamplesFromServerState;
  currentWorkspaceSelected: WorkSpaceBoxState;
  onRadarChartDragEnd: (index: number, value: number) => void;
  variationState: VariationState;
  workspaceBox: WorkSpaceBoxState;
  onClickOnGenerateButton: () => void;
  onClickOnSampleHistoryButtons: (mode: string) => void;
  workSpacesState: WorkSpacesState;
  onClickOnWorkSPaceBox: (index: number) => void;
  setWorkSpaceState: (value: React.SetStateAction<WorkSpacesState>) => void;
  onChange: (
    value: number,
    type: "Kick" | "Snare" | "Cymbal" | "Variation",
    setTheRestToZero: boolean
  ) => void;
  KSCSliderState: KSCSliderState;
  setKSCSliderState: (value: React.SetStateAction<KSCSliderState>) => void;
  onOneDVariationSliderChange: (x: number) => void;
  onClickOnDirectionVariationButton: () => void;
  setVariationState: (value: React.SetStateAction<VariationState>) => void;
}

const MobilePortraitContainer: FC<MobilePortraitContainerProps> = ({
  audioSamplesFromServerState,
  currentWorkspaceSelected,
  onRadarChartDragEnd,
  variationState,
  workspaceBox,
  onClickOnGenerateButton,
  onClickOnSampleHistoryButtons,
  workSpacesState,
  onClickOnWorkSPaceBox,
  setWorkSpaceState,
  onChange,
  KSCSliderState,
  setKSCSliderState,
  onOneDVariationSliderChange,
  onClickOnDirectionVariationButton,
  setVariationState,
}) => {
  return (
    <div className="mobile-portrait-main-container">
      <div className="mobile-portrait-waveform-container">
        <WaveFormContainer
          audioSampleState={audioSamplesFromServerState}
          workspaceBox={currentWorkspaceSelected}
        />
      </div>
      <div className="mobile-portrait-generate-container">
        <GenerateButton
          onClick={onClickOnGenerateButton}
          onClickOnSampleHistoryButtons={onClickOnSampleHistoryButtons}
        />
      </div>
      <div className="mobile-portrait-sliders-container">
        <VerticalSliderContainer
          onChange={onChange}
          type="Kick"
          value={KSCSliderState.kickValue}
          setKSCSliderState={setKSCSliderState}
        />
        <VerticalSliderContainer
          onChange={onChange}
          type="Snare"
          value={KSCSliderState.snareValue}
          setKSCSliderState={setKSCSliderState}
        />
        <VerticalSliderContainer
          onChange={onChange}
          type="Cymbal"
          value={KSCSliderState.cymbalValue}
          setKSCSliderState={setKSCSliderState}
        />
      </div>
      <div className="mobile-portrait-variation-container">
        <OneDVariationContainerMobile
          value={variationState.variationValue.x}
          onOneDVariationSliderChange={onOneDVariationSliderChange}
          onClickOnDirectionVariationButton={
            onClickOnDirectionVariationButton
          }
          setVariationState={setVariationState}
        />
      </div>
    </div>
  );
};

export default MobilePortraitContainer;
